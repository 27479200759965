// Icons
import faceBook from "../../shared/icon-social/images/facebook.svg";
import twitter from "../../shared/icon-social/images/twitter.svg";
import instagram from "../../shared/icon-social/images/instagram.svg";
import youTube from "../../shared/icon-social/images/youtube.svg";
import linkedIn from "../../shared/icon-social/images/linkedin.svg";

import {
    TWITTER_URL,
    INSTAGRAM_URL,
    YOUTUBE_URL,
    LINKEDIN_URL,
    FACEBOOK_URL
} from "../../../constants/app-constants";

export const BLOGS_URL = process.env.HOST_ENV === "PRODUCTION" ? "https://www.cars24.ae/blog/" : "https://blog-ae.c24.tech/blog/";

export const BRANDS_LIST = [
    {
        name: "Nissan"
    },
    {
        name: "Toyota"
    },
    {
        name: "Renault"
    },
    {
        name: "Mitsubishi"
    },
    {
        name: "Ford"
    }
];

export const USEFUL_LINKS = [
    {
        name: "About Us",
        href: "/about-us/",
        target: "_self"
    },
    {
        name: "Contact Us",
        href: "/contact/",
        target: "_self"
    },
    {
        name: "Terms of Use",
        href: "/terms-of-use/",
        target: "_self"
    },
    {
        name: "Privacy Policy",
        href: "/privacy-policy/",
        target: "_self"
    },
    {
        name: "Sitemap",
        href: "/sitemap/",
        target: "_self"
    },
    {
        name: "FAQs",
        href: "/faq/",
        target: "_self"
    }
];

export const SOCIAL_URLS = [
    {
        altText: "Facebook",
        imgSrc: faceBook,
        href: FACEBOOK_URL
    },
    {
        altText: "Twitter",
        imgSrc: twitter,
        href: TWITTER_URL
    },
    {
        altText: "Instagram",
        imgSrc: instagram,
        href: INSTAGRAM_URL
    },
    {
        altText: "Youtube",
        imgSrc: youTube,
        href: YOUTUBE_URL
    },
    {
        altText: "Linkedin",
        imgSrc: linkedIn,
        href: LINKEDIN_URL
    }
];
