import { AE_CITY } from "../../../constants/app-constants";
import { findByKey } from "../../../utils/helpers/find-by-key";
import saveCookie from "../../../utils/helpers/save-cookie";
import Types from "./types";

const toggleLocationPicker = (showLocationPicker) => ({
    type: Types.TOGGLE_LOCATION_PICKER,
    showLocationPicker
});

const updateCountry = (country) => ({
    type: Types.UPDATE_COUNTRY,
    country
});

const updateLocationScreen = (activeScreen) => ({
    type: Types.UPDATE_LOCATION_SCREEN,
    activeScreen
});

const updateCity = (city) => (dispatch, store) => {
    const cityList = store().config.cityList;
    const isCityName = isNaN(Number(city));
    const selectedCity = findByKey(cityList, isCityName ? "name" : "id", city);
    saveCookie(AE_CITY, selectedCity.code);
    return dispatch({
        type: Types.UPDATE_CITY,
        city,
        cityList
    });
};

export {
    toggleLocationPicker,
    updateCountry,
    updateLocationScreen,
    updateCity
};
