/* eslint-disable react/no-unknown-property */
/* eslint-disable camelcase */
import React from "react";
import PropTypes from "prop-types";
import styles from "./styles.css";
import FacebookIcon from "../../shared/icon-social/images/facebook-dark.svg";
import TwitterIcon from "../../shared/icon-social/images/twitter-dark.svg";
import InstagramIcon from "../../shared/icon-social/images/instagram-dark.svg";
import YoutubeIcon from "../../shared/icon-social/images/youtube-dark.svg";
import LinkedinIcon from "../../shared/icon-social/images/linkedin-dark.svg";
import { Link } from "react-router-dom";
import { trackDesktopCustomEventsAE } from "../../../tracking/desktop/tracking";
import { DOWNLOAD_APP_URL, DEFAULT_CITY, TWITTER_URL, INSTAGRAM_URL, YOUTUBE_URL, LINKEDIN_URL, FACEBOOK_URL, GEOGRAPHIES_LINKS, DOWNLOAD_APP_URL_IOS } from "../../../constants/app-constants";
import SocialIcon from "../../shared/icon-social";
import LazyImage from "../../shared/lazy-image";
import appStore from "../../shared/icon-social/images/app-store-new.svg";
import dashedLowercase from "../../../utils/helpers/dashed-lowercase";
import { BLOGS_URL, BRANDS_LIST } from "../../ae.mobile/footer/constants";
import scrollToTop from "../../../utils/helpers/scroll-to-top";
import { TOP_SELL_CITIES } from "../../../constants/seller-app-constants";
import { appUrl } from "../../../constants/url-constants";

const FooterV2 = (props) => {
    const { cityList = [] } = props || {};

    const featuredCities = (cityList || []).map(({ name }) => ({
        title: name,
        url: `${appUrl()}/buy-used-cars-${dashedLowercase(name)}/`
    }));

    const popularBrands = BRANDS_LIST.map(({ name }) => ({
        title: name,
        url: `${appUrl()}/buy-used-${dashedLowercase(name)}-cars-${dashedLowercase(DEFAULT_CITY.AE.city_name)}/`
    }));

    const featuredCitiesForSell = TOP_SELL_CITIES.map(({ name }) => ({
        title: name,
        url: `${appUrl()}/sell-used-car-${dashedLowercase(name)}/`
    }));

    const onClickLink = (e) => {
        trackDesktopCustomEventsAE("footerClick", {
            eventlabel: e.target.getAttribute("eventlabel") || "NA"
        });
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const onAnchorClick = (e) => {
        scrollToTop();
        trackDesktopCustomEventsAE("footerClick", {
            eventlabel: e.target.getAttribute("eventlabel") || "NA"
        });
    };

    return (
        <React.Fragment>
            <footer styleName={"styles.footer"}>
                <div className={"container"}>
                    <div className={"row"} styleName={"styles.rowSpace"}>
                        <div className={"col"}>
                            <div styleName={"styles.colWidth"}>
                                <h3 styleName={"styles.heading"}>KEEP IN TOUCH</h3>
                                <ul styleName={"styles.socialMedia"}>
                                    <li>
                                        <a href={FACEBOOK_URL} target="_blank"><LazyImage className={"img-fluid"} src={FacebookIcon} alt="Facebook" /></a>
                                    </li>
                                    <li>
                                        <a href={TWITTER_URL} target="_blank"><LazyImage className={"img-fluid"} src={TwitterIcon} alt="Twitter" /></a>
                                    </li>
                                    <li>
                                        <a href={INSTAGRAM_URL} target="_blank"><LazyImage className={"img-fluid"} src={InstagramIcon} alt="Instagram" /></a>
                                    </li>
                                    <li>
                                        <a href={YOUTUBE_URL} target="_blank"><LazyImage className={"img-fluid"} src={YoutubeIcon} alt="Youtube" /></a>
                                    </li>
                                    <li>
                                        <a href={LINKEDIN_URL} target="_blank"><LazyImage className={"img-fluid"} src={LinkedinIcon} alt="Linkedin" /></a>
                                    </li>
                                </ul>
                                <h3 styleName={"styles.headingApp"}>Experience CARS24 App on Mobile</h3>
                                <div styleName={"styles.footerlinkApp"}>
                                    <a href={DOWNLOAD_APP_URL} target="" styleName={"styles.iconPlay"}>
                                        <SocialIcon name="googlePlay" />
                                    </a>
                                    <a href={DOWNLOAD_APP_URL_IOS} target="" styleName={"styles.iconPlay"}>
                                        <LazyImage src={appStore} />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className={"col"}>
                            <h3 styleName={"styles.secondHeading"}>Popular Cities</h3>
                            <ul styleName={"styles.footerLink"}>
                                {
                                    featuredCities.map((cityData, index) =>
                                        <li key={`${cityData.title}_${index}`}><a onClick={onAnchorClick} eventlabel={`Popular city - ${cityData.title}`} href={cityData.url}>{cityData.title}</a></li>)
                                }
                            </ul>
                            <h3 styleName={`styles.secondHeading styles.topCity`}>Top Selling Cities</h3>
                            <ul styleName={"styles.footerLink"}>
                                {featuredCitiesForSell.map((cityData, index) =>
                                    <li key={`${cityData.title}_${index}`}><a onClick={onAnchorClick} eventlabel={`Popular city - ${cityData.title}`} href={cityData.url}>{cityData.title}</a></li>)
                                }
                            </ul>
                        </div>
                        <div className={"col"}>
                            <h3 styleName={"styles.secondHeading"}>Useful Links</h3>
                            <div styleName={"styles.menuRow"}>
                                <ul styleName={"styles.footerLink styles.footerLinkBlock"}>
                                    <li><Link onClick={onClickLink} to="/about-us/" eventlabel="About Us">About Us</Link></li>
                                    <li><Link onClick={onClickLink} to="/contact/" eventlabel="24x7 Support">Contact Us</Link></li>
                                    <li><Link onClick={onClickLink} to="/terms-of-use/" eventlabel="Terms of Use">Terms of Use</Link></li>
                                    <li><Link onClick={onClickLink} to="/privacy-policy/" eventlabel="Privacy Policy">Privacy Policy</Link></li>
                                    <li><Link onClick={onClickLink} to="/sitemap/" eventlabel="Sitemap">Sitemap</Link></li>

                                </ul>
                                <ul styleName={"styles.footerLink styles.footerLinkSecond"}>
                                    <li><Link onClick={onClickLink} to="/faq/" eventlabel="FAQs">FAQ</Link></li>
                                    <li><a onClick={onAnchorClick} eventlabel="Blog" href={BLOGS_URL} target="_blank">Blog</a></li>
                                </ul>
                            </div>
                        </div>
                        <div className={"col"}>
                            <h3 styleName={"styles.secondHeading"}>Popular Brands</h3>
                            <ul styleName={"styles.footerLink styles.footerLinkBlock"}>
                                {
                                    popularBrands.map((brand, index) =>
                                        <li key={`${brand.title}_${index}`}><a onClick={onAnchorClick} eventlabel={`Popular brand - ${brand.title}`} href={brand.url}>{brand.title}</a></li>)
                                }
                            </ul>
                        </div>
                        <div className={"col"}>
                            <h3 styleName={"styles.secondHeading"}>Other Geographies</h3>
                            <ul styleName={"styles.footerLink styles.footerLinkBlock"}>
                                {GEOGRAPHIES_LINKS.map((usefulLink, index) => (
                                    <li key={`${usefulLink?.name}_${index}`}>
                                        <a
                                            onClick={onClickLink}
                                            target={usefulLink?.target}
                                            href={usefulLink?.href}
                                            eventtext={usefulLink?.name}
                                        > {usefulLink?.name}
                                        </a>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    );
};

FooterV2.propTypes = {
    data: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
    cityList: PropTypes.array
};

export default FooterV2;
