import { createTypes } from "../../../utils/redux/create-types";

export default createTypes(
    `
    TOGGLE_LOCATION_PICKER
    UPDATE_COUNTRY
    UPDATE_LOCATION_SCREEN
    UPDATE_CITY
`,
    {
        prefix: "locationpickerpopup/"
    }
);
